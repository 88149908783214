@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  @apply bg-stone-900 
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
